<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>题库管理</el-breadcrumb-item>
        <el-breadcrumb-item>题目列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-header">
      <el-menu class="yt-menu" :default-active="activeRootIndex" mode="horizontal" @select="selectRootMenu">
        <el-menu-item index="1" :disabled="menuLoading">学校</el-menu-item>
        <el-menu-item index="0" :disabled="menuLoading">平台</el-menu-item>
      </el-menu>
      <div class="yt-header-toolbar">
        <el-input
          v-model="searchQuestionForm.questionName"
          placeholder="通过题目名称进行搜索"
          class="yt-search"
          @keydown.enter.native="searchQuestionBanks(false)"
        >
          <i slot="suffix" class="el-input__icon el-icon-search" @click="searchQuestionBanks(false)"></i>
        </el-input>
        <template v-if="activeRootIndex === '1'">
          <el-select
            v-model="searchQuestionForm.tagIds"
            placeholder="搜索题目标签"
            class="yt-search-select"
            filterable
            clearable
            multiple
            :collapse-tags="searchQuestionForm.tagIds.length > 2"
            @change="searchQuestionBanks(false)"
          >
            <el-option v-for="item in tagOptions" :key="item.tagId" :label="item.name" :value="item.tagId"> </el-option>
          </el-select>
          <el-button v-if="activeIndex === '1'" type="primary" size="small" @click="createQuestion">
            <div>
              <i class="el-icon-circle-plus-outline"></i>
              <p>新增</p>
            </div>
          </el-button>
          <el-button v-if="activeIndex === '1'" size="small" @click="moveToBank">
            <div>
              <YTIcon :href="'#icon-yidong'"></YTIcon>
              <p>移动</p>
            </div>
          </el-button>
          <el-button size="small" @click="toDelete(false)">
            <div>
              <YTIcon :href="'#icon-shanchu'"></YTIcon>
              <p>删除</p>
            </div>
          </el-button>
        </template>
      </div>
    </div>
    <div class="yt-container">
      <div class="question-bank-content">
        <el-menu
          class="yt-menu"
          :class="{ 'is-platform': activeRootIndex === '0' }"
          :default-active="activeIndex"
          mode="horizontal"
          @select="selectMenu"
        >
          <el-menu-item index="1">
            <div class="icon">
              <YTIcon :href="'#icon-tiku2'" style="height: 12px"></YTIcon>
            </div>
            题库列表
          </el-menu-item>
          <template v-if="activeRootIndex === '1'">
            <el-menu-item index="2">
              <div class="icon">
                <YTIcon :href="'#icon-zhishidian3'"></YTIcon>
              </div>
              知识点
            </el-menu-item>
          </template>
        </el-menu>
        <!--题库列表-->
        <template v-if="activeIndex === '1'">
          <el-input v-model="keyword" class="yt-search search" placeholder="通过名称/标签搜索题库" @keydown.enter.native="searchQuestionBanks">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="searchQuestionBanks"></i>
          </el-input>
          <div class="list-container">
            <div class="list">
              <div
                v-for="(item, index) in questionBankList"
                :key="index"
                class="item"
                :class="{ active: activeBankIndex === index }"
                @click="handleClickQuestionBank(index, item.questionBankId)"
              >
                <template v-if="item.hasOwnProperty('cover')">
                  <el-image class="cover" :src="item.cover">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
                <template v-else>
                  <div class="cover"></div>
                </template>
                <div style="width: calc(100% - 39px);">
                  <div class="row">
                    <p>{{ item.name }}</p>
                    <div class="tab-container" v-if="activeRootIndex === '1' && item.hasOwnProperty('tabs') && item.tabs.length > 0">
                      <div class="item">
                        <div>{{ item.tabs[0].name }}</div>
                      </div>
                      <el-popover placement="top" title="题库标签" width="184" trigger="hover">
                        <div class="tab-list">
                          <div v-for="(tab, index) in item.tabs" :key="index" class="item">
                            <div>{{ tab.name }}</div>
                          </div>
                        </div>
                        <div slot="reference" class="dots">
                          <YTIcon :href="'#icon-gengduo1'"></YTIcon>
                        </div>
                      </el-popover>
                    </div>
                  </div>
                  <div class="row">
                    <div style="max-width: 134px">
                      <el-tooltip effect="dark" :content="item.questionCount.toString()" placement="top" :open-delay="1000">
                        <span style="padding-right: 8px">数量: {{ item.questionCount }}</span>
                      </el-tooltip>
                      <el-tooltip v-if="activeRootIndex === '1'" effect="dark" :content="item.creatorName" placement="top" :open-delay="1000">
                        <span style="border-left: 1px solid #000000; padding-left: 8px">创建者: {{ item.creatorName }}</span>
                      </el-tooltip>
                    </div>
                    <div style="width: 70px">
                      <span>{{ $formatTime(item.createdTime, 'yyyy-MM-dd') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <el-input
            v-model="knowledgePointKeyword"
            class="yt-search search"
            placeholder="通过知识点名称筛选"
            @keydown.enter.native="toFilterNode('knowledge-tree')"
          >
            <i slot="suffix" class="el-input__icon el-icon-search" @click="toFilterNode('knowledge-tree')"></i>
          </el-input>
          <div class="list-container" style="height: calc(100% - 161px)" v-loading="knowledgePointLoading" element-loading-text="加载中">
            <el-tree
              :data="knowledgePoints"
              :props="knowledgePointProps"
              node-key="value"
              ref="knowledge-tree"
              :filter-node-method="filterNode"
              show-checkbox
              check-strictly
              @check="handleClickTreeNode"
            >
              <div class="tree-node" slot-scope="{ node, data }">
                <p>{{ node.label }}</p>
                <div>{{ data.knowQuestionCount }} / {{ data.questionCount }}</div>
              </div>
            </el-tree>
          </div>
          <div class="footer">
            <el-button size="small" @click="resetCheckedNodes('knowledge-tree')">
              <div>
                <YTIcon :href="'#icon-zhongzhi'"></YTIcon>
                <p>重置</p>
              </div>
            </el-button>
          </div>
        </template>
      </div>
      <div class="question-content">
        <!--筛选-->
        <div
          class="filter-container"
          :style="{
            height: activeRootIndex === '1' ? (isPackUp ? '120px' : '50px') : '87px',
            overflow: 'hidden'
          }"
        >
          <div v-show="activeRootIndex === '1'" class="pack-up" @click="isPackUp = !isPackUp">
            <p>{{ !isPackUp ? '展开' : '收起' }}</p>
            <YTIcon :class="['icon', isPackUp ? 'active' : '']" :href="'#icon-open'"></YTIcon>
          </div>
          <template v-for="(filter, key, index) in filters">
            <div v-if="activeRootIndex === '1' || index !== 1" :key="index" class="row">
              <div class="label">{{ filter.label }}:</div>
              <template v-if="key !== 'other'">
                <div
                  v-for="(child, fIndex) in filter.children"
                  :key="fIndex"
                  :class="[
                    'item default-item',
                    {
                      active: searchQuestionForm.searchForm[key] === child.value
                    }
                  ]"
                  @click="handleClickFilter(key, child.value)"
                >
                  {{ child.label }}
                </div>
                <template v-if="index === 1">
                  <div class="checkbox-container">
                    <el-checkbox v-model="searchQuestionForm.searchForm.hasKnowledge" @change="reSearchQuestions">未绑定知识点</el-checkbox>
                    <el-checkbox
                      v-if="searchQuestionForm.searchForm.questionType === '5'"
                      v-model="searchQuestionForm.searchForm.hasTestCase"
                      @change="reSearchQuestions"
                      >无测试用例</el-checkbox
                    >
                  </div>
                </template>
              </template>
              <template v-else>
                <div v-for="(child, fIndex) in filter.children" :key="fIndex" class="item other-item">
                  <!--排序-->
                  <template v-if="child.type === 'sort'">
                    <div @click="child.function">
                      {{ child.label }}
                      <YTIcon
                        :href="
                          searchQuestionForm.searchForm.useCountDesc === ''
                            ? '#icon-moren'
                            : searchQuestionForm.searchForm.useCountDesc
                            ? '#icon-jiangxu'
                            : '#icon-shengxu'
                        "
                      />
                    </div>
                  </template>
                  <!--下拉菜单-->
                  <template v-else-if="child.type === 'singleSelect'">
                    <el-dropdown trigger="click" @command="child.function" placement="bottom-start">
                      <span class="select">{{ child.label }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          v-for="(option, oIndex) in child.options"
                          :key="oIndex"
                          :command="option.value"
                          :class="{
                            active: searchQuestionForm.searchForm[child.property] === option.value
                          }"
                        >
                          {{ option.label }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                  <!--多选下拉菜单-->
                  <template v-else-if="activeRootIndex === '1' && child.type === 'multipleSelect'">
                    <el-dropdown trigger="click" @command="child.function" placement="bottom-start" :hide-on-click="false">
                      <span class="select">{{ child.label }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                      <el-dropdown-menu slot="dropdown" style="max-height: calc(100vh - 400px);overflow-y: auto">
                        <el-dropdown-item
                          v-for="(option, oIndex) in child.options"
                          :key="oIndex"
                          :command="option.value"
                          :class="{
                            active: searchQuestionForm.searchForm[child.property].includes(option.value)
                          }"
                        >
                          {{ option.label }}
                          <i
                            class="el-icon-check"
                            style="margin-left: 5px"
                            :style="{
                              opacity: searchQuestionForm.searchForm[child.property].includes(option.value) ? 1 : 0
                            }"
                          ></i>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                  <template v-else-if="activeRootIndex === '1' && child.type === 'tree' && activeIndex === '1'">
                    <el-dropdown trigger="click" placement="bottom-start">
                      <span class="select">{{ child.label }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                      <el-dropdown-menu slot="dropdown">
                        <el-input
                          v-model="knowledgePointDownKeyword"
                          class="yt-search search"
                          placeholder="通过知识点名称筛选"
                          @keydown.enter.native="toFilterNode('knowledge-tree-down')"
                        >
                          <i
                            slot="suffix"
                            class="el-input__icon el-icon-search"
                            @click="toFilterNodeDown('knowledge-tree-down', knowledgePointDownKeyword)"
                          ></i>
                        </el-input>
                        <div class="list-container">
                          <el-tree
                            :data="knowledgePointsDown"
                            :props="knowledgePointProps"
                            node-key="value"
                            ref="knowledge-tree-down"
                            :filter-node-method="filterNode"
                            show-checkbox
                            check-strictly
                          >
                          </el-tree>
                        </div>
                        <div class="footer">
                          <el-button type="primary" @click="toSearchByKnowledge">确定</el-button>
                          <el-button size="small" @click="resetCheckedNodesDown('knowledge-tree-down')">
                            <div>
                              <YTIcon :href="'#icon-zhongzhi'"></YTIcon>
                              <p>重置</p>
                            </div>
                          </el-button>
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </div>
        <!--全选-->
        <div class="select-all" v-if="activeRootIndex === '1' && questionList.length !== 0">
          <el-checkbox :indeterminate="isIndeterminate" v-model="isSelectAll" @change="handleCheckAllChange">全选题目</el-checkbox>
        </div>
        <!--题目列表-->
        <div
          class="question-container"
          :style="{
            height: activeRootIndex === '1' ? (isPackUp ? 'calc(100% - 156px)' : 'calc(100% - 92px)') : 'calc(100% - 87px)'
          }"
          v-loading="questionListLoading"
          element-loading-text="加载中"
        >
          <div
            class="list"
            :style="{
              height: questionListLoading ? '100%' : 'auto',
              overflowY: questionListLoading ? 'hidden' : 'auto'
            }"
          >
            <!--题目卡片-->
            <template v-if="questionList.length > 0">
              <div v-for="(question, index) in questionList" :key="index" class="item">
                <el-checkbox
                  v-if="activeRootIndex === '1'"
                  class="checkbox"
                  v-model="question.isChecked"
                  @change="handleCheckQuestion($event, question.id)"
                ></el-checkbox>
                <div style="width: 100%">
                  <!--题目卡片头部-->
                  <div class="header">
                    <div class="question-type">
                      {{ ytConstant.questionType.getLabel(question.questionType)[0] }}
                    </div>
                    <el-popover
                      placement="top"
                      width="184"
                      trigger="hover"
                      popper-class="paper-info"
                      :disabled="question.status !== 0"
                      @show="showAudit(question.id)"
                    >
                      <div :id="'audit-info-' + question.id">
                        <div class="paper-info-item">
                          审核人:
                          {{
                            questionAuditInfo[question.id] && questionAuditInfo[question.id].hasOwnProperty('auditorName')
                              ? questionAuditInfo[question.id].auditorName
                              : ''
                          }}
                        </div>
                        <div class="paper-info-item">
                          审核时间:
                          {{ questionAuditInfo[question.id] ? $formatTime(questionAuditInfo[question.id].auditTime, 'yyyy-MM-dd') : '' }}
                        </div>
                      </div>
                      <p slot="reference" class="audit">
                        <YTIcon :href="auditIcons[question.status]"></YTIcon>
                      </p>
                    </el-popover>
                  </div>
                  <div class="body">
                    <!--题干-->
                    <div class="stem">
                      {{ index + 1 }}.&nbsp;
                      <div v-html="question.stem"></div>
                    </div>
                    <component
                      :is="questionComponents[question.questionType]"
                      :question="question"
                      :readOnly="true"
                      :hasTool="true"
                      @refresh="searchQuestions"
                      style="margin-top: 16px"
                    ></component>
                    <div class="correct-answer">
                      <div style="flex-shrink: 0;letter-spacing: 1px">正确答<span style="letter-spacing: 5px">案:</span></div>
                      <div v-html="question.questionAnswer"></div>
                    </div>
                    <div style="display: flex">
                      <div style="flex-shrink: 0">
                        <span style="letter-spacing: 12px">解</span>
                        <span style="letter-spacing: 5px">析:</span>
                      </div>
                      <!--<template v-if="question.analysisHasCode">-->
                      <!--<pre v-highlight="question.analysis"><code style="width: 100%;white-space: pre-wrap;"></code></pre>-->
                      <!--</template>-->
                      <!--<template v-else>-->
                      <p v-html="question.analysis"></p>
                      <!--</template>-->
                    </div>
                    <div v-if="activeRootIndex === '1'" class="tag-and-knowledge" ref="tagAndKnowledge">
                      <div class="tk-item tk-select-item">
                        <span style="letter-spacing: 12px">标</span>
                        <span style="letter-spacing: 5px">签:</span>
                        <template v-for="(tag, tIndex) in question.tags">
                          <div :key="tIndex">
                            <el-tooltip effect="dark" :disabled="tag.name.length < 4" :content="tag.name" placement="top" :open-delay="1000">
                              <div class="tk-point" :key="tIndex" v-if="tIndex < showSize">
                                {{ tag.name }}
                              </div>
                            </el-tooltip>
                          </div>
                        </template>
                        <el-popover
                          placement="top"
                          width="202"
                          trigger="hover"
                          title="题目标签"
                          v-if="question.hasOwnProperty('tags') && showSize < question.tags.length"
                        >
                          <div class="tk-select-item tab-list" style="margin-bottom: 5px">
                            <template v-for="(tagPop, tPopIndex) in question.tags">
                              <div :key="tPopIndex">
                                <el-tooltip
                                  effect="dark"
                                  :disabled="tagPop.name.length < 4"
                                  :content="tagPop.name"
                                  placement="top"
                                  :open-delay="1000"
                                >
                                  <div class="tk-point" :key="tPopIndex" v-if="tPopIndex >= showSize" style="margin-bottom: 5px">
                                    {{ tagPop.name }}
                                  </div>
                                </el-tooltip>
                              </div>
                            </template>
                          </div>
                          <YTIcon :href="'#icon-gengduo1'" slot="reference" style="margin-right: 8px"></YTIcon>
                        </el-popover>
                        <!--编辑题目标签-->
                        <el-dropdown trigger="click" placement="bottom-start" @visible-change="handleTagDropdownChange($event, index)">
                          <YTIcon :href="'#icon-xinzeng'"></YTIcon>
                          <el-dropdown-menu slot="dropdown">
                            <el-input
                              v-model="tagUpdateKeyword"
                              class="yt-search search"
                              placeholder="通过标签名称筛选"
                              @keydown.enter.native="toFilterNodeUpdate('tag-tree-update', tagUpdateKeyword, index)"
                            >
                              <i
                                slot="suffix"
                                class="el-input__icon el-icon-search"
                                @click="toFilterNodeUpdate('tag-tree-update', tagUpdateKeyword, index)"
                              ></i>
                            </el-input>
                            <div class="list-container" style="height: calc(100vh - 500px);">
                              <template v-if="question.modifyTagModel">
                                <el-tree :data="tagsUpdate" node-key="value" ref="tag-tree-update" :filter-node-method="filterNode" show-checkbox>
                                </el-tree>
                              </template>
                            </div>
                            <div class="footer">
                              <el-button type="primary" @click="toUpdateTagsForQuestion(question, index)">确定</el-button>
                            </div>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                      <div class="tk-item tk-select-item">
                        <span style="margin-right: 5px">知识点:</span>
                        <template v-for="(knowledge, knowledgeIndex) in question.knowledgePoints">
                          <div :key="knowledgeIndex">
                            <el-tooltip
                              effect="dark"
                              :disabled="knowledge.hasOwnProperty('knowledgeName') && knowledge.knowledgeName.length < 4"
                              :content="knowledge.hasOwnProperty('knowledgeName') ? knowledge.knowledgeName : '-'"
                              placement="top"
                              :open-delay="1000"
                            >
                              <div class="tk-point" :key="knowledgeIndex" v-if="knowledgeIndex < showSize">
                                {{ knowledge.hasOwnProperty('knowledgeName') ? knowledge.knowledgeName : '-' }}
                              </div>
                            </el-tooltip>
                          </div>
                        </template>
                        <el-popover
                          placement="top"
                          width="202"
                          trigger="hover"
                          title="题目知识点"
                          v-if="question.hasOwnProperty('knowledgePoints') && showSize < question.knowledgePoints.length"
                        >
                          <div class="tk-select-item tab-list" style="margin-bottom: 5px; max-height: calc(100vh / 2); overflow-y: auto">
                            <template v-for="(knowledgePop, knowledgePopIndex) in question.knowledgePoints">
                              <div :key="knowledgePopIndex">
                                <el-tooltip
                                  effect="dark"
                                  :disabled="knowledgePop.hasOwnProperty('knowledgeName') && knowledgePop.knowledgeName.length < 4"
                                  :content="knowledgePop.hasOwnProperty('knowledgeName') ? knowledgePop.knowledgeName : '-'"
                                  placement="top"
                                  :open-delay="1000"
                                >
                                  <div class="tk-point" style="margin-bottom: 5px" :key="knowledgePopIndex" v-if="knowledgePopIndex >= showSize">
                                    {{ knowledgePop.hasOwnProperty('knowledgeName') ? knowledgePop.knowledgeName : '-' }}
                                  </div>
                                </el-tooltip>
                              </div>
                            </template>
                          </div>
                          <YTIcon :href="'#icon-gengduo1'" slot="reference" style="margin-right: 8px"></YTIcon>
                        </el-popover>
                        <!--编辑题目知识点-->
                        <el-dropdown trigger="click" placement="bottom-start" @visible-change="handleDropdownChange($event, index)">
                          <YTIcon :href="'#icon-xinzeng'"></YTIcon>
                          <el-dropdown-menu slot="dropdown">
                            <el-input
                              v-model="knowledgePointUpdateKeyword"
                              class="yt-search search"
                              placeholder="通过知识点名称筛选"
                              @keydown.enter.native="toFilterNodeUpdate('knowledge-tree-update', knowledgePointUpdateKeyword, index)"
                            >
                              <i
                                slot="suffix"
                                class="el-input__icon el-icon-search"
                                @click="toFilterNodeUpdate('knowledge-tree-update', knowledgePointUpdateKeyword, index)"
                              ></i>
                            </el-input>
                            <div class="list-container" style="height: calc(100vh - 500px);">
                              <template v-if="question.modifyKnowledgeModel">
                                <el-tree
                                  :data="knowledgePointsUpdate"
                                  :props="knowledgePointProps"
                                  node-key="value"
                                  ref="knowledge-tree-update"
                                  :filter-node-method="filterNode"
                                  show-checkbox
                                  check-strictly
                                >
                                </el-tree>
                              </template>
                            </div>
                            <div class="footer">
                              <el-button @click="toClearKnowledgePointsForQuestion(question, index)">清空</el-button>
                              <el-button type="primary" @click="toUpdateKnowledgePointsForQuestion(question, index)">确定</el-button>
                            </div>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                    </div>
                  </div>
                  <!--题目卡片底部-->
                  <div class="footer">
                    <div>
                      <el-popover
                        placement="top"
                        width="184"
                        trigger="hover"
                        popper-class="paper-info"
                        :disabled="question.status !== 0"
                        @show="showAudit(question.id)"
                      >
                        <div :id="'audit-info-' + question.id">
                          <div class="paper-info-item">
                            审核人:
                            {{
                              questionAuditInfo[question.id] && questionAuditInfo[question.id].hasOwnProperty('auditorName')
                                ? questionAuditInfo[question.id].auditorName
                                : ''
                            }}
                          </div>
                          <div class="paper-info-item">
                            审核时间:
                            {{ questionAuditInfo[question.id] ? $formatTime(questionAuditInfo[question.id].auditTime, 'yyyy-MM-dd') : '' }}
                          </div>
                        </div>
                      </el-popover>
                      <p class="inactive">难度系<span style="letter-spacing: 5px">数:</span>{{ question.difficulty / 10 }}</p>
                      <p>{{ ytConstant.difficultType.getLabel(question.difficulty) }}</p>
                      <el-popover
                        placement="top"
                        width="237"
                        trigger="hover"
                        popper-class="paper-info"
                        :disabled="question.useCount === 0"
                        @show="showPaperInfo(question)"
                      >
                        <div class="paper-info-container" :id="'paper-info-' + question.id" :style="{ height: question.useCount * 32 + 'px' }">
                          <div v-for="(paper, index) in questionPaperInfo[question.id]" :key="index" class="paper-info-item">
                            <p>{{ index + 1 }}.{{ paper.name }}</p>
                            <p>
                              {{ $formatTime(paper.createTime, 'yyyy-MM-dd') }}
                            </p>
                          </div>
                        </div>
                        <p slot="reference" class="popover-reference">使用次<span style="letter-spacing: 5px">数:</span>{{ question.useCount }}次</p>
                      </el-popover>
                      <el-popover placement="top" width="237" trigger="hover" popper-class="paper-info">
                        <div class="paper-info-item">预计完成时间： {{ question.expectedTime }} 分钟</div>
                        <div class="paper-info-item">创建人： {{ question.name }}</div>
                        <div class="paper-info-item">创建时间： {{ question.createdTime }}</div>
                        <YTIcon slot="reference" :href="'#icon-gengduo1'" />
                      </el-popover>
                    </div>
                    <div v-if="activeRootIndex === '1'">
                      <!--                      <div class="button" @click="toComment(question)">-->
                      <!--                        <YTIcon :href="'#icon-pinglun'" />-->
                      <!--                        评论({{ question.commentCount }})-->
                      <!--                      </div>-->
                      <div class="button" v-if="question.status !== 0" @click="toAudit(question)">
                        <YTIcon :href="'#icon-shenhe1'" />
                        审核
                      </div>
                      <div class="button" @click="modifyQuestions(question)">
                        <YTIcon :href="'#icon-caozuobianji'" />
                        编辑
                      </div>
                      <div class="button" @click="toDelete(question)">
                        <YTIcon :href="'#icon-shanchu'" />
                        删除
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div
              class="empty"
              :style="{ height: activeRootIndex === '1' ? (isPackUp ? 'calc(100vh - 390px)' : 'calc(100vh - 320px)') : 'calc(100vh - 316px)' }"
              v-else
            >
              <template v-if="!questionListLoading" :style="height">
                <YTIcon :href="'#icon-zanwushuju'" />
              </template>
            </div>
            <div class="page">
              <div>
                <Page
                  class="yt-page"
                  :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
                  :total="total"
                  :current="pageNum + 1"
                  :page-size-opts="[5, 10, 20, 50]"
                  :page-size="pageSize"
                  show-elevator
                  show-sizer
                  show-total
                  @on-change="changePage"
                  @on-page-size-change="changePageSize"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ConfirmDialog ref="auditQuestionDialog" :title="'审核题目'" @on-ok="auditQuestion">
      <div style="width: 219px; margin: 0 auto;">
        是否发布该题目
      </div>
    </ConfirmDialog>

    <ConfirmDialog ref="deleteQuestionDialog" :title="'删除题目'" @on-ok="deleteQuestion">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        删除题目后，使用了该题目的试卷与练习还保留该题的快照,你还要继续吗？
      </div>
    </ConfirmDialog>

    <QuestionCUDialog
      ref="questionCUDialog"
      :tagList="tagsUpdate"
      :knowledgePointList="knowledgePointsUpdate"
      @refresh="updateQuestionBanks"
      @refreshQuestions="searchQuestionBanks(true)"
    />

    <QuestionMoveDialog ref="questionMoveDialog" @on-success="moveConfirm" :moveQuestionBankList="moveQuestionBankList" />

    <!--  评论modal  -->
    <QuestionCommentsModal ref="commentDialog" />
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import TrueOrFalse from './questions/TrueOrFalse'
import Choice from './questions/Choice'
import Code from './questions/Code'
import ConfirmDialog from '../../../common/dialog/ConfirmDialog'
import QuestionCUDialog from '@components/common/dialog/QuestionCUDialog'
import questionBankApi from '@api/questionBank'
import questionApi from '@api/question'
import tagApi from '@api/tag'
import MonacoEditor from '@components/common/MonacoEditor'
import QuestionMoveDialog from '@components/common/dialog/QuestionMoveDialog'
import QuestionCommentsModal from '@components/manage/question/list/QuestionCommentsModal'
export default {
  name: 'QuestionList',
  components: {
    QuestionCommentsModal,
    QuestionMoveDialog,
    YTIcon,
    TrueOrFalse,
    Choice,
    ConfirmDialog,
    QuestionCUDialog,
    Code,
    MonacoEditor
  },
  data() {
    return {
      activeRootIndex: '1',
      keyword: '',
      isPackUp: true,
      searchQuestionForm: {
        questionName: '',
        questionBankId: '',
        tagIds: [],
        searchForm: {
          questionType: '', //题型
          questionStatus: '', //状态
          difficultyType: null, //难度
          useCountDesc: '', //使用次数
          questionTagIds: [], //标签
          knowledgePointIds: [], //知识点
          hasKnowledge: false,
          hasTestCase: false
        }
      },
      activeBankIndex: 0,
      tagOptions: [], //下拉菜单标签列表
      activeIndex: '1', //左侧题库: 1/知识点: 2
      questionBankList: [], //题目列表
      moveQuestionBankList: [],
      knowledgePointKeyword: '', //知识点菜单搜索
      knowledgePoints: [], //知识点菜单树
      knowledgePointDownKeyword: '', //知识点筛选搜索
      knowledgePointsDown: [], //知识点筛选树
      knowledgePointProps: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      filters: {
        questionType: {
          label: '题型',
          children: [{ label: '全部', value: '' }, ...this.ytConstant.questionType.getMenu()]
        },
        questionStatus: {
          label: '状态',
          children: [
            { label: '全部', value: '' },
            { label: '已审核', value: '0' },
            { label: '未审核', value: '2' },
            { label: '草稿', value: '1' }
          ]
        },
        other: {
          label: '其他',
          children: [
            {
              label: '使用次数',
              property: 'useCountDesc',
              type: 'sort',
              function: this.sortByUseCount
            },
            {
              label: '难度筛选',
              property: 'difficultyType',
              type: 'singleSelect',
              function: this.selectDifficulty,
              options: [...this.ytConstant.difficultType.getMenu()]
            },
            {
              label: '标签筛选',
              property: 'questionTagIds',
              type: 'multipleSelect',
              function: this.selectQuestionTag,
              options: []
            },
            { label: '知识点筛选', property: 'knowledgePointIds', type: 'tree' }
          ]
        }
      },
      isSelectAll: false, //是否全选
      isIndeterminate: false, //是否开启不确定全选
      questionList: [], //题目列表
      questionListLoading: true, //是否加载中
      questionSelection: [], //被选中题目
      questionPaperInfo: {}, //悬停显示使用了题目的试卷列表
      questionAuditInfo: {}, //悬停显示题目审核信息
      auditIcons: ['#icon-yishenhe', '#icon-caogao', '#icon-weishenhe'], //审核图标
      questionComponents: [TrueOrFalse, Choice, Choice, null, null, Code], //题目组件
      difficulties: [
        { label: '简单', value: '0' },
        { label: '中等', value: '1' },
        { label: '困难', value: '2' }
      ],
      pageNum: 0,
      pageSize: 10,
      total: 0,
      totalLeft: ['two', 'three', 'four', 'five'],
      toAuditQuestionId: '', //待审核的题目id
      toDeleteQuestionIds: [], //待删除的题目id
      createVisible: false, //是否显示新增弹窗
      isAdd: true, //新增or修改
      toModifyQuestionId: '', //带修改题目id
      inputVisible: false, //导入题目弹窗
      setBankVisible: false, //移动题目到题库弹窗
      moveToBankId: '', //移动到的新题库
      modifyForm: {},
      screenWidth: document.body.clientWidth, //屏幕宽度
      showSize: 0, //根据屏幕宽度展示标签的个数
      tagKeyword: '', //编辑题目标签时的关键词
      tagCloseTimeOut: null, //题目标签定时器
      addTagIndex: -1,
      knowledgePointUpdateKeyword: '', //题目知识点搜索关键词
      knowledgePointsUpdate: [], //编辑知识点用的树
      knowledgePointLoading: true, //知识点加载
      tagUpdateKeyword: '', //题目标签搜索关键词
      tagsUpdate: [], //编辑标签用的树
      menuLoading: false, //企业平台菜单防抖
      codeTypes: {
        java: 'java',
        JavaScript: 'javascript',
        c: 'c',
        'c++': 'cpp',
        python: 'python',
        bash: 'shell'
      }
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
    this.calculateSize(document.body.clientWidth)
    this.getKnowledgeTree()
    this.searchQuestionBanks()
    this.getTags()
  },
  watch: {
    screenWidth(val) {
      this.calculateSize(val)
    }
  },
  methods: {
    toComment(row) {
      // 评论
      this.$refs['commentDialog'].open(row)
    },
    moveToBank() {
      if (this.questionSelection.length === 0) {
        this.$message.warning('请选择需要移动的题目')
      } else {
        this.$refs.questionMoveDialog.open(this.questionSelection)
        this.getQuestionBanks()
      }
    },
    getQuestionBanks() {
      let payload = {
        keyword: '',
        questionName: '',
        questionTagIds: [],
        type: this.activeRootIndex
      }
      questionBankApi.searchByForm(payload).then(res => {
        this.moveQuestionBankList = res.res
      })
    },
    searchQuestionBanks(isRefresh) {
      //搜索题库列表
      if (this.activeIndex === '1') {
        let payload = {
          keyword: this.keyword,
          questionName: this.searchQuestionForm.questionName,
          questionTagIds: this.searchQuestionForm.tagIds,
          type: this.activeRootIndex
        }
        questionBankApi.searchByForm(payload).then(res => {
          if (res.code === 0 && res.res.length === 0) {
            this.searchQuestionForm.questionBankId = ''
            this.questionListLoading = false
            this.menuLoading = false
            this.questionList = []
          } else if (!isRefresh) {
            this.searchQuestionForm.questionBankId = res.res[0].questionBankId
          }
          this.questionBankList = res.res
          let find = this.questionBankList.findIndex(q => q.questionCount > 0)
          if (find !== -1) {
            this.activeBankIndex = find
            this.searchQuestionForm.questionBankId = res.res[find].questionBankId
          } else {
            this.searchQuestionForm.questionBankId = res.res[this.activeBankIndex].questionBankId
          }
          this.reSearchQuestions()
        })
      } else {
        this.getKnowledgeTreeByCondition()
        this.reSearchQuestions()
      }
    },
    searchQuestions() {
      //搜索题目列表
      //判断如果在企业题库未获取到时, 不进行筛选
      if (this.activeRootIndex === '1' && this.activeIndex === '1' && this.questionBankList.length === 0) {
        return
      }
      this.questionListLoading = true
      let payload = { ...this.searchQuestionForm }
      payload.searchForm = { ...this.searchQuestionForm.searchForm }
      payload.searchForm.questionStatus = payload.searchForm.questionStatus === '' ? null : payload.searchForm.questionStatus
      payload.searchForm.difficultyType = payload.searchForm.difficultyType === '' ? null : payload.searchForm.difficultyType
      payload.searchForm.hasKnowledge = payload.searchForm.hasKnowledge ? false : null
      payload.searchForm.hasTestCase = payload.searchForm.hasTestCase ? true : null
      payload.searchForm.questionType = payload.searchForm.questionType === '' ? null : payload.searchForm.questionType
      payload.type = this.activeRootIndex
      questionApi
        .searchByForm(payload, this.pageNum, this.pageSize)
        .then(res => {
          this.questionList = res.res.data.map(item => {
            if (!item.hasOwnProperty('analysis')) {
              // item.analysisHasCode = item.analysis.indexOf('<pre>') && item.analysis.indexOf('</pre>') !== -1
              // item.analysis = item.analysis
              //   .replace(/<[pre /pre> code /code]+>/g, '')
              //   .replace(/<br>/g, '\n')
              //   .replace(/&lt;/g, '<')
              //   .replace(/&gt;/g, '>')
              //   .replace(/&amp;/g, '&')
              //   .replace(/&nbsp;/g, ' ')
              item.analysis = ''
            }
            item.isChecked = false
            if (item.hasOwnProperty('knowledgePoints')) {
              item.knowledgePointIds = item.knowledgePoints.map(point => {
                return point.knowledgeId
              })
            } else {
              item.knowledgePointIds = []
            }
            if (!item.hasOwnProperty('tagIds')) {
              item.tagIds = []
            }
            item.modifyTagModel = false
            item.modifyKnowledgeModel = false
            // let codeBegin = item.stem.indexOf('<script>')
            // let codeEnd = item.stem.indexOf('<\/script>')
            // if (codeBegin !== -1 && codeEnd !== -1) {
            //   item.codeStem = {
            //     codeType: 'JavaScript',
            //     template: item.stem.substring(codeBegin, codeEnd + 9)
            //   }
            //   item.hasCodeStem = true
            // } else {
            //   item.hasCodeStem = false
            // }
            return item
          })
          this.total = res.res.total
          this.$forceUpdate()
        })
        .finally(() => {
          this.questionListLoading = false
          this.menuLoading = false
        })
    },
    reSearchQuestions() {
      this.pageNum = 0
      this.total = 0
      this.searchQuestions()
    },
    getKnowledgeTree() {
      //获取知识点树
      questionApi.getKnownLedgeTree().then(res => {
        this.knowledgePointsDown = res.res
        this.knowledgePointsUpdate = res.res
      })
    },
    getKnowledgeTreeByCondition() {
      //获取知识点树通过条件
      this.knowledgePointLoading = true
      let payload = {
        questionName: this.searchQuestionForm.questionName,
        tagIds: this.searchQuestionForm.tagIds
      }
      questionApi
        .getKnownLedgeTreeByCondition(payload)
        .then(res => {
          this.knowledgePoints = res.res
        })
        .finally(() => {
          this.knowledgePointLoading = false
        })
    },
    toFilterNode(ref) {
      //知识点树过滤点击触发(左侧)
      this.$refs[ref].filter(this.knowledgePointKeyword)
      this.$refs[ref].setCheckedNodes([])
    },
    toFilterNodeDown(ref, keyword) {
      //知识点树过滤点击触发(筛选)
      this.$refs[ref][0].filter(keyword)
      this.$refs[ref][0].setCheckedNodes([])
    },
    toClearKnowledgePointsForQuestion(question, index) {
      //清空题目知识点
      this.$refs['knowledge-tree-update'][0].setCheckedKeys([])
      this.toUpdateKnowledgePointsForQuestion(question, index)
    },
    toFilterNodeUpdate(ref, keyword, index) {
      //知识点树过滤点击触发(题目卡片)
      this.$refs[ref][0].filter(keyword)
      this.$refs[ref][0].setCheckedKeys(
        ref === 'knowledge-tree-update' ? this.questionList[index].knowledgePointIds : this.questionList[index].tagIds
      )
    },
    filterNode(value, data) {
      //知识点树过滤
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    resetCheckedNodes(ref) {
      //知识点树选择重置
      this.$refs[ref].setCheckedKeys([])
    },
    resetCheckedNodesDown(ref) {
      //知识点树选择重置
      this.$refs[ref][0].setCheckedKeys([])
    },
    toSearchByKnowledge() {
      this.searchQuestionForm.searchForm.knowledgePointIds = this.$refs['knowledge-tree-down'][0].getCheckedKeys()
      this.reSearchQuestions()
    },
    handleClickTreeNode(data, checkData) {
      let knowledgePoints = []
      checkData.checkedNodes.forEach(item => {
        knowledgePoints.push(item.id)
      })
      this.searchQuestionForm.searchForm.knowledgePointIds = knowledgePoints
      this.reSearchQuestions()
    },
    getTags() {
      let payload = {
        keyword: '',
        typeId: this.ytConstant.tagType.QUESTION
      }
      tagApi.searchTagByTypeId(payload).then(res => {
        if (res.code === 0) {
          this.tagOptions = res.res
          let tags = res.res.map(item => {
            return {
              label: item.name,
              value: item.tagId
            }
          })
          this.filters.other.children[2].options = tags
          this.tagsUpdate = tags
          this.$forceUpdate()
        }
      })
    },
    selectRootMenu(index) {
      this.activeRootIndex = index
      this.activeIndex = '1'
      this.menuLoading = true
      this.total = 0
      this.searchQuestionForm.questionName = ''
      this.searchQuestionForm.tagIds = []
      this.searchQuestionForm.searchForm.knowledgePointIds = []
      this.searchQuestionForm.searchForm.questionType = ''
      this.searchQuestionForm.searchForm.questionStatus = ''
      this.searchQuestionForm.searchForm.difficultyType = null
      this.searchQuestionForm.searchForm.useCountDesc = ''
      this.searchQuestionForm.searchForm.questionTagIds = []
      this.searchQuestionBanks()
    },
    handleClickQuestionBank(index, id) {
      this.isSelectAll = false
      this.activeBankIndex = index
      this.searchQuestionForm.questionBankId = id
      this.pageSize = 10
      this.pageNum = 0
      this.searchQuestions()
    },
    selectMenu(index) {
      this.activeIndex = index
      this.total = 0
      this.searchQuestionForm.questionName = ''
      this.searchQuestionForm.tagIds = []
      this.searchQuestionForm.searchForm.knowledgePointIds = []
      this.searchQuestionForm.searchForm.questionType = ''
      this.searchQuestionForm.searchForm.questionStatus = ''
      this.searchQuestionForm.searchForm.difficultyType = null
      this.searchQuestionForm.searchForm.useCountDesc = ''
      this.searchQuestionForm.searchForm.questionTagIds = []
      if (index === '2') {
        this.searchQuestionForm.questionBankId = null
      }
      this.searchQuestionBanks()
    },
    handleClickFilter(key, value) {
      this.searchQuestionForm.searchForm[key] = value
      this.reSearchQuestions()
    },
    sortByUseCount() {
      if (this.searchQuestionForm.searchForm.useCountDesc === '') {
        this.searchQuestionForm.searchForm.useCountDesc = true
      } else if (this.searchQuestionForm.searchForm.useCountDesc) {
        this.searchQuestionForm.searchForm.useCountDesc = false
      } else {
        this.searchQuestionForm.searchForm.useCountDesc = ''
      }
      this.searchQuestions()
    },
    selectDifficulty(command) {
      this.searchQuestionForm.searchForm.difficultyType = command
      this.reSearchQuestions()
    },
    selectQuestionTag(command) {
      let tagIndex = this.searchQuestionForm.searchForm.questionTagIds.indexOf(command)
      if (tagIndex !== -1) {
        this.searchQuestionForm.searchForm.questionTagIds.splice(tagIndex, 1)
      } else {
        this.searchQuestionForm.searchForm.questionTagIds.push(command)
      }
      this.reSearchQuestions()
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.searchQuestions()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      this.searchQuestions()
    },
    handleCheckAllChange(val) {
      if (val) {
        this.questionSelection = this.questionList.map(item => {
          item.isChecked = true
          return item.id
        })
      } else {
        this.questionList.map(item => {
          item.isChecked = false
        })
        this.questionSelection = []
      }
      this.isIndeterminate = false
      this.$forceUpdate()
    },
    handleCheckQuestion(value, questionId) {
      if (Array.isArray(value) && value.includes(undefined)) {
        return
      }
      if (value) {
        this.questionSelection.push(questionId)
      } else {
        this.questionSelection.splice(this.questionSelection.indexOf(questionId), 1)
      }
      let checkedCount = this.questionSelection.length
      this.isSelectAll = checkedCount === this.questionList.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.questionList.length
    },
    showPaperInfo(question) {
      if (!this.questionPaperInfo.hasOwnProperty(question.id)) {
        let loading = null
        if (question.useCount > 2) {
          loading = this.$onLoading('#paper-info-' + question.id)
        }
        this.$set(this.questionPaperInfo, question.id, true) //先放一个空的, 如果悬停结束接口不结束下次进来不会走接口
        questionApi
          .getQuestionPaperInfo(question.id)
          .then(res => {
            this.$set(this.questionPaperInfo, question.id, res.res)
          })
          .finally(() => {
            if (question.useCount > 2) {
              loading.close()
            }
          })
      }
    },
    showAudit(questionId) {
      if (!this.questionAuditInfo.hasOwnProperty(questionId)) {
        let loading = this.$onLoading('#audit-info-' + questionId)
        this.$set(this.questionAuditInfo, questionId, true) //先放一个空的, 如果悬停结束接口不结束下次进来不会走接口
        questionApi
          .getQuestionAuditInfo(questionId)
          .then(res => {
            this.$set(this.questionAuditInfo, questionId, res.res)
          })
          .finally(() => {
            loading.close()
          })
      }
    },
    toAudit(question) {
      this.toAuditQuestionId = question.id
      this.$refs.auditQuestionDialog.open()
    },
    auditQuestion() {
      questionApi.auditQuestion(this.toAuditQuestionId, 0).then(res => {
        if (res.res === true) {
          this.$message.success('审核成功')
          this.searchQuestions()
        }
      })
    },
    toDelete(question) {
      if (question) {
        this.toDeleteQuestionIds = [question.id]
      } else {
        if (this.questionSelection.length === 0) {
          this.$message.warning('请先选择题目')
          return
        } else {
          this.toDeleteQuestionIds = this.questionSelection
        }
      }
      this.$refs.deleteQuestionDialog.open()
    },
    deleteQuestion() {
      this.toDeleteQuestionIds.forEach(item => {
        this.questionList.forEach((s, index) => {
          if (s.id === item) {
            this.questionList.splice(index, 1)
            this.total -= 1
            if (this.questionList.length === 0) {
              this.searchQuestionBanks(true)
            }
          }
        })
      })
      questionApi.deleteQuestions(this.toDeleteQuestionIds).then(res => {
        if (res.res === true) {
          this.isSelectAll = false
          this.$message.success('删除成功')
          this.questionBankList[this.activeBankIndex].questionCount -= this.toDeleteQuestionIds.length
          this.questionSelection = []
          //this.searchQuestions()
        }
      })
    },
    moveConfirm(moveToBankId) {
      this.moveToBankId = moveToBankId
      questionApi.moveQuestions(this.moveToBankId, this.questionSelection).then(res => {
        if (res.res === true) {
          this.$message.success('移动成功')
          this.questionSelection = []
          this.searchQuestionBanks(true)
        }
      })
    },
    modifyQuestions(question) {
      this.$refs.questionCUDialog.open(false, question)
    },
    refreshUpdateQuestion(data) {
      if (data)
        questionApi.getQuestionInfo(data).then(res => {
          this.$set(this.questionList, this.modifyForm.index, res.res)
        })
      else {
        this.questionBankList[this.activeBankIndex].questionCount += 1
        this.reSearchQuestions()
      }
    },
    createQuestion() {
      this.$refs.questionCUDialog.open(true)
    },
    calculateSize(width) {
      if (width <= 1440) {
        this.showSize = 3
      } else if (width > 1440 && width < 1920) {
        this.showSize = 4
      } else {
        this.showSize = 6
      }
    },
    toUpdateTagsForQuestion(question, index) {
      //变更题目标签
      let tagIds = this.$refs['tag-tree-update'][0].getCheckedKeys()
      if (question.hasOwnProperty('tagIds') && tagIds.sort().toString() === question.tagIds.sort().toString()) {
        return
      }
      questionApi.updateTagsForQuestion([question.id], tagIds).then(res => {
        if (res.res === true) {
          this.$message.success('标签已变更')
          question.tagIds = tagIds
          question.tags = this.$refs['tag-tree-update'][0].getCheckedNodes().map(item => {
            return {
              name: item.label,
              tagId: item.value
            }
          })
          this.$forceUpdate()
        }
      })
    },
    toUpdateKnowledgePointsForQuestion(question, index) {
      //变更题目知识点
      let knowledgePointIds = this.$refs['knowledge-tree-update'][0].getCheckedKeys()
      if (question.hasOwnProperty('knowledgePointIds') && knowledgePointIds.sort().toString() === question.knowledgePointIds.sort().toString()) {
        return
      }
      questionApi.updateKnowledgePointsForQuestion([question.id], knowledgePointIds).then(res => {
        if (res.res === true) {
          this.$message.success('知识点已变更')
          question.knowledgePointIds = knowledgePointIds
          question.knowledgePoints = this.$refs['knowledge-tree-update'][0].getCheckedNodes().map(item => {
            return {
              knowledgeName: item.label,
              knowledgeId: item.value
            }
          })
          this.$forceUpdate()
        }
      })
    },
    handleTagDropdownChange(visible, index) {
      if (!visible) {
        this.tagUpdateKeyword = ''
        this.$refs['tag-tree-update'][0].setCheckedKeys([])
        this.$set(this.questionList[index], 'modifyTagModel', false)
        this.toFilterNodeUpdate('tag-tree-update', this.tagUpdateKeyword, index)
      } else {
        this.$set(this.questionList[index], 'modifyTagModel', true)
        this.$nextTick(() => {
          if (this.questionList[index].hasOwnProperty('tagIds')) {
            this.$refs['tag-tree-update'][0].setCheckedKeys(this.questionList[index].tagIds)
          }
        })
      }
    },
    handleDropdownChange(visible, index) {
      if (!visible) {
        this.knowledgePointUpdateKeyword = ''
        this.$refs['knowledge-tree-update'][0].setCheckedKeys([])
        this.$set(this.questionList[index], 'modifyKnowledgeModel', false)
        this.toFilterNodeUpdate('knowledge-tree-update', this.knowledgePointUpdateKeyword, index)
      } else {
        this.$set(this.questionList[index], 'modifyKnowledgeModel', true)
        this.$nextTick(() => {
          if (this.questionList[index].hasOwnProperty('knowledgePointIds')) {
            this.$refs['knowledge-tree-update'][0].setCheckedKeys(this.questionList[index].knowledgePointIds)
          }
        })
      }
    },
    updateQuestionBanks(data, diffculty, answer) {
      this.searchQuestions()
      this.questionList.forEach((item, index) => {
        if (data.id === item.id) {
          item.stem = data.stem
          item.analysis = data.analysis
            .replace(/<[pre /pre> code /code]+>/g, '')
            .replace(/<br>/g, '\n')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&amp;/g, '&')
            .replace(/&nbsp;/g, ' ')
          item.difficulty = diffculty
          if (item.questionType === 1) {
            item.options.forEach((s, n) => {
              const arrayE = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
              if (s.correct === true) {
                item.questionAnswer = arrayE[n]
              }
            })
          } else if (item.questionType === 2) {
            let str = ''
            item.options.forEach((s, n) => {
              const arrayE = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
              if (s.correct === true) {
                str += arrayE[n]
              }
            })
            item.questionAnswer = str
          } else if (item.questionType === 4) {
            item.answer = answer
            item.questionAnswer = answer
          }
          if (item.questionBankId !== data.questionBankId) {
            this.questionList.splice(index, 1)
            this.questionBankList.forEach(i => {
              if (i.questionBankId === data.questionBankId) {
                i.questionCount += 1
              } else if (this.searchQuestionForm.questionBankId === i.questionBankId) {
                i.questionCount -= 1
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/variables';
@import '~@/theme/filterDropdownMenu';
.yt-main {
  color: #000000;
  .yt-header {
    .el-button {
      width: 80px;
    }
  }
  .yt-container {
    padding: 10px 10px 0;
    .flexStyle(flex, space-between);
    .question-bank-content {
      background-color: #ffffff;
      width: 280px;
      height: 100%;
      .yt-menu {
        .flexStyle(flex, space-between);
        height: 50px;
        padding: 6px 30px 0;
        margin: 0 !important;
        border-bottom: 1px solid #e2e4e8 !important;
        &:before {
          display: none;
        }
        &:after {
          display: none;
        }
        .el-menu-item {
          margin: 0 !important;
          color: #dbdbdb !important;
          display: flex;
          align-items: center;
          &:hover {
            color: #000000 !important;
            .icon {
              background-color: #448bff;
            }
          }
        }
        .is-active {
          color: #000000 !important;
          border-bottom: 4px solid #409eff;
          .icon {
            background-color: #448bff;
          }
        }
        .icon {
          .flexStyle(flex, center);
          width: 20px;
          height: 20px;
          background-color: #dbdbdb;
          border-radius: 50%;
          margin-right: 6px;
          svg {
            width: 14px;
            height: 14px;
            color: #ffffff;
            border-radius: unset;
            margin-right: 0;
          }
        }
        &.is-platform {
          justify-content: center;
          .el-menu-item {
            border-bottom: none;
          }
        }
      }
      .title {
        .flexStyle(flex);
        height: 50px;
        .icon {
          .flexStyle(flex, center);
          width: 20px;
          height: 20px;
          background-color: #dbdbdb;
          border-radius: 50%;
          margin-right: 6px;
          svg {
            width: 14px;
            height: 14px;
            color: #ffffff;
            border-radius: unset;
            margin-right: 0;
          }
        }
      }
      .search {
        margin: 12px 0;
        padding: 0 10px;
        height: 32px;
        ::v-deep .el-input__suffix {
          right: 15px;
        }
      }
      .footer {
        .flexStyle(flex, center, center);
        height: 55px;
        border-top: 1px solid #e2e4e8;
        .el-button {
          width: 78px;
          height: 32px;
          line-height: 6px;
        }
      }
    }
    .question-content {
      background-color: #ffffff;
      width: calc(100% - 290px);
      height: 100%;
    }
  }
}
//题库列表
.list-container {
  height: calc(100% - 106px);
  overflow-y: auto;
  .list {
    .item {
      .flexStyle(flex, flex-start);
      height: 56px;
      border-bottom: 1px solid #f0f2f3;
      &:hover {
        background-color: #f0f0f0;
      }
      .cover {
        width: 32px;
        height: 32px;
        margin: 0 0 0 10px;
        border-radius: 7px;
        ::v-deep .image-slot {
          height: 100%;
          .flexStyle(flex, center, center);
        }
      }
      .row {
        .flexStyle(flex, space-between);
        color: #666666;
        font-size: 10px;
        padding: 0 4px 0 13px;
        &:nth-of-type(1) {
          margin-bottom: 3px;
        }
        p {
          font-size: @small;
          font-weight: bold;
          color: #000000;
        }
        div {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .tab-container {
        .flexStyle(flex, flex-start);
        .item {
          max-width: 100px;
          height: 18px;
          line-height: 18px;
          background: #edf4ff;
          border-radius: 10px;
          padding: 0 8px;
          color: #448bff;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .dots {
          margin-left: 6px;
          cursor: pointer;
          svg {
            vertical-align: middle;
            fill: #448bff;
          }
        }
      }
    }
    .active {
      background-color: #f0f0f0;
    }
  }
  ::v-deep .el-tree__empty-text {
    display: none;
  }
  ::v-deep .el-tree-node__children {
    overflow: visible;
  }
  ::v-deep .el-tree-node__content {
    flex-grow: 1;
  }
  .tree-node {
    width: calc(100% - 48px);
    .flexStyle(flex, space-between, center);
    flex-shrink: 0;
    p {
      color: #000000;
      font-size: @small;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    div {
      font-size: 10px;
    }
  }
}
//题库列表悬停标签
.tab-list {
  display: flex;
  flex-wrap: wrap;
  .item {
    font-size: 10px;
    height: 18px;
    line-height: 18px;
    background: #edf4ff;
    border-radius: 10px;
    color: #448bff;
    padding: 0 8px;
    margin-right: auto;
    margin-bottom: 10px;
  }
}
//筛选列表
.filter-container {
  border-bottom: 1px solid #e2e4e8;
  padding: 16px 20px 0;
  position: relative;
  .pack-up {
    position: absolute;
    width: 53px !important;
    height: 25px;
    line-height: 25px;
    background: #f7f7f7;
    border-radius: 13px;
    border: 1px solid #e6e6e6;
    cursor: pointer;
    right: 25px;
    top: 13px;
    .flexStyle(flex, flex-start, center);
    p {
      margin-left: 8px;
      font-size: @small;
      width: 24px !important;
      user-select: none; //不被选中
    }

    svg {
      width: 9px;
      height: 14px;
      margin-left: 4px;
      margin-top: 14px;
      margin-right: 6px;
      transition: all 0.2s ease-in-out;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .active {
      -webkit-transform: translateY(-50%) rotate(180deg);
      transform: translateY(-50%) rotate(180deg);
    }
  }
  .row {
    .flexStyle(flex, flex-start);
    height: 20px;
    margin-bottom: 16px;
    color: #000000;
    &:last-child {
      margin-bottom: 0;
    }
    .label {
      font-size: @small;
      margin-right: 20px;
      height: 20px;
      line-height: 20px;
    }
    .item {
      height: 20px;
      line-height: 20px;
      margin: 0 8px;
      padding: 0 15px;
      font-size: @small;
      cursor: pointer;
    }
    .default-item {
      &:hover {
        border-radius: 11px;
        background: #448bff;
        color: #ffffff;
      }
    }
    .active {
      border-radius: 11px;
      background: #448bff;
      color: #ffffff;
    }
    .checkbox-container {
      .flexStyle(flex, flex-start, center);
      border-left: 1px solid #d8d8d8;
      margin-left: 17px;
      .el-checkbox {
        margin-left: 40px;
        margin-right: 0;
        ::v-deep .el-checkbox__label {
          font-size: @small;
          padding-left: 6px;
          color: #000000 !important;
        }
      }
    }
    .other-item {
      padding: 0;
      margin: 0 22px 0 8px;
      -moz-user-select: none; /*火狐*/
      -webkit-user-select: none; /*webkit浏览器*/
      -ms-user-select: none; /*IE10*/
      user-select: none; //禁止双击选中文字
      svg {
        color: #a6a6a6;
      }
    }
    .asc {
      transform: rotate(180deg);
    }
    .select {
      font-size: @small;
      &:hover {
        color: #448bff;
        i {
          transform: rotate(180deg);
        }
      }
    }
    .el-dropdown {
      .flexStyle(flex, flex-start, center);
      height: 20px;
      line-height: 20px;
      color: #000000;
    }
    .el-icon-arrow-down {
      font-size: @small;
      color: #999999 !important;
      transition: all 0.2s ease-in-out;
    }
  }
}
//全选
.select-all {
  .flexStyle(flex, flex-start, center);
  height: 36px;
  border-bottom: 1px solid rgba(226, 228, 232, 0.3);
  padding-left: 20px;
  .el-checkbox {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    ::v-deep .el-checkbox__label {
      font-size: @small;
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }
}
//题目列表
.question-container {
  overflow-y: auto;
  .list {
    background-color: #f8f8f8;
  }
  .item {
    display: flex;
    background-color: #ffffff;
    margin-bottom: 9px;
    padding: 0 0 0 20px;
    .checkbox {
      height: 16px;
      margin-top: 19px;
      margin-right: 4px;
      ::v-deep .el-checkbox__label {
        display: none;
      }
    }
    .header {
      .flexStyle(flex, flex-start);
      margin: 16px 0 6px;
      .question-type {
        width: 23px;
        height: 23px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        font-weight: bold;
        background: #448bff;
        border-radius: 50%;
        margin-left: 4px;
        margin-right: 16px;
        font-size: 14px;
      }
      .audit {
        .flexStyle(flex, center, center);
        height: 23px;
        overflow: hidden;
      }
      svg {
        font-size: 68px;
      }
    }
    //题目部分
    .body {
      font-size: @small;
      .stem {
        display: flex;
        ::v-deep xmp {
          width: 100%;
          white-space: pre-wrap;
        }
      }
      .stem-code {
        height: 200px;
        margin-right: 40px;
        margin-top: 15px;
      }
      .correct-answer {
        display: flex;
        color: #06c281;
        margin: 16px 0;
      }
      .tag-and-knowledge {
        .flexStyle(flex, flex-start, center);
        margin: 14px 0 0;
        padding-bottom: 15px;
        border-bottom: 1px dotted #e9e9e9;
        height: 20px;
        .tk-item {
          .flexStyle(flex, flex-start, center);
          width: calc(100% / 2);
          &:last-child {
            margin-left: 8px;
            border-left: 1px solid #d8d8d8;
            padding-left: 16px;
          }
        }
      }
    }
    .footer {
      .flexStyle(flex, space-between, center);
      height: 20px;
      margin: 10px 0;
      font-size: @small;
      padding-right: 30px;
      div {
        .flexStyle(flex, flex-start, center);
      }
      p {
        height: 18px;
        line-height: 20px;
        border-right: 1px solid #d8d8d8;
        padding-right: 18px;
        margin-right: 16px;
        cursor: pointer;
        &:hover {
          color: #448bff;
        }
      }
      .inactive {
        cursor: initial;
        &:hover {
          color: #000;
        }
      }
      svg {
        color: #999999;
        &:hover {
          color: #448bff;
        }
      }
      .button {
        color: #666666;
        margin-left: 17px;
        cursor: pointer;
        &:hover {
          color: #448bff;
          svg {
            color: #448bff;
          }
        }
        svg {
          font-size: 14px;
          margin-right: 6px;
        }
      }
    }
  }
  .page {
    .flexStyle(flex, center, center);
    height: 78px;
    background-color: #ffffff;
    div {
      width: 100%;
      height: 25px;
      padding: 0 30px;
    }
    .yt-page {
      margin-top: 0;
    }
  }
}
.tk-select-item {
  ::v-deep .el-input__inner {
    max-width: 80px;
    height: 22px;
    border-color: #edf4ff;
    border-radius: 2px;
    color: #448bff;
    margin-right: 8px;
    background-color: #edf4ff;
    padding-left: 12px;
    font-size: @small;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:focus {
      border-color: #448bff;
    }
  }
  ::v-deep .el-input__prefix {
    right: 18px;
    left: unset;
    line-height: 21px;
    i {
      background-color: #ffffff;
      border-radius: 50%;
      cursor: pointer;
      font-size: 12px;
    }
  }
  ::v-deep .el-input__suffix {
    .el-input__icon {
      display: none;
    }
  }
  svg {
    color: #999999;
    font-size: 15px;
  }
}
.tk-point {
  max-width: 70px;
  height: 22px;
  line-height: 22px;
  border-color: #edf4ff;
  border-radius: 2px;
  color: #448bff;
  margin-right: 8px;
  padding: 0 8px;
  background-color: #edf4ff;
  font-size: @small;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  i {
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
  }
  &:focus {
    border-color: #448bff;
  }
}
::v-deep .el-checkbox__input {
  .el-checkbox__inner {
    width: 16px;
    height: 16px;
    &:after {
      left: 5px;
      top: 2px;
    }
  }
}
.empty {
  .flexStyle(flex, center, center);
  background-color: #ffffff;
  svg {
    font-size: 100px;
  }
}
</style>
<style lang="less">
.tk-select-down {
  margin: 4px 0 0 !important;
  .popper__arrow {
    display: none;
  }
  .el-select-dropdown__list {
    padding: 0;
  }
}
.paper-info {
  padding: 5px 0 0;
  .paper-info-container {
    max-height: 150px;
    overflow-y: auto;
  }
  .paper-info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    padding-left: 12px;
    padding-right: 14px;
    color: #090909;
    font-size: 12px;
    &:hover {
      background-color: #f0f0f0;
    }
    p {
      &:nth-of-type(1) {
        max-width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
.code-tab-container {
  width: calc(100% - 30px);
}
</style>
